<template>
  <div>
    <VueHighcharts :key="groupBy" :options="chartOptions"></VueHighcharts>
  </div>
</template>
<script>
import VueHighcharts from 'vue3-highcharts';
import lowerCase from 'lodash-es/lowerCase';
import capitalize from 'lodash-es/capitalize';
import map from 'lodash-es/map';
import find from 'lodash-es/find';
import last from 'lodash-es/last';

export default {
  components: {
    VueHighcharts,
  },
  props: {
    metric: Object,
    values: Array,
    global: Array,
    groupBy: String,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column',
        },
        xAxis: {
          categories: [],
        },
        title: {
          text: this.name,
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: '',
          },
          stackLabels: {
            enabled: false,
            style: {
              fontWeight: 'bold',
            },
          },
        },
        legend: {
          align: 'right',
          x: -30,
          verticalAlign: 'top',
          y: 25,
          floating: true,
          backgroundColor: 'white',
          borderColor: '#CCC',
          borderWidth: 1,
          shadow: false,
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [],
      },
    };
  },
  methods: {
    bindOption() {
      const groupBy = last(this.metric.group_by.split(','));
      this.chartOptions.title.text = `${capitalize(
        lowerCase(this.metric.metric_name).replace('count ', ''),
      )} by ${this.groupBy}`;
      // this.chartOptions.yAxis.title.text = this.metric.measured_against_field;
      const categories = map(this.global, groupBy);

      const totalData = [];
      const itemData = [];
      map(categories, category => {
        const item = find(this.values, item => item[groupBy] === category);
        itemData.push(parseFloat(item && item.final_value ? item.final_value : 0));

        const globalItem = find(this.global, item => item[groupBy] === category);
        let value = 0;
        if (globalItem) {
          value = parseFloat(globalItem.final_value);
        }
        if (item) {
          value = value - parseFloat(item.final_value);
        }
        totalData.push(value);
      });

      this.chartOptions.xAxis.categories = categories;
      let text = null;
      if (this.metric.measured_against_fields) {
        text = last(
          this.metric.measured_against_values
            .replaceAll('[', '')
            .replaceAll(']', '')
            .split(','),
        );
      }
      this.chartOptions.series = [
        {
          name: 'Others',
          data: totalData,
          color: '#71a8df',
        },
        {
          name: this.metric.measured_against_field
            ? capitalize(lowerCase(this.metric.measured_against_field))
            : text,
          data: itemData,
          color: '#286eb4',
        },
      ];
    },
  },
  created() {
    this.bindOption();
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    value: {
      immediate: true,
      handler(current) {
        this.filter = current;
      },
    },
  },
};
</script>
